import {
  useMutation,
  MutationFunctionOptions,
  ApolloError,
} from '@apollo/client';

import {UPDATE_LOAN_OFFICER} from '../mutations/updateLoanOfficer';
import {LenderObject} from '../gql/graphql';

export type UseUpdateOfficer = {
  updateOfficer: (options: MutationFunctionOptions) => any;
  loading: boolean;
  error?: ApolloError;
  data?: LenderObject;
};

export const useUpdateOfficer = (): UseUpdateOfficer => {
  const [updateOfficer, {loading, error, data}] =
    useMutation(UPDATE_LOAN_OFFICER);

  return {
    updateOfficer: (options: MutationFunctionOptions) => updateOfficer(options),
    loading,
    error,
    data: data?.lenderUpdateOfficer?.officer,
  };
};
