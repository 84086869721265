import {gql} from '@apollo/client';

export const UPDATE_LOAN_OFFICER = gql`
  mutation UpdateLoanOfficer($loanApplicationId: ID!, $officerId: ID!) {
    lenderUpdateOfficer(
      loanApplicationId: $loanApplicationId
      officerId: $officerId
    ) {
      officer {
        id
      }
    }
  }
`;
