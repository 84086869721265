import {ApolloError, useQuery} from '@apollo/client';
import {LenderObject} from '../gql/graphql';
import {ALL_USERS} from '../queries/lenders';
import {useEdges} from './useEdges';

type UseRolesType = {
  loading: boolean;
  error: ApolloError | undefined;
  users: LenderObject[] | undefined;
};
type Props = {
  role?: string;
};

const defaultProps = {
  role: undefined,
};
export const useAllUsers = ({
  role,
}: Props | undefined = defaultProps): UseRolesType => {
  const {data, loading, error} = useQuery(ALL_USERS, {variables: {role}});
  const nodes = useEdges(data?.lenders);
  return {
    users: nodes,
    loading,
    error,
  };
};
