import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {Dialog} from '../../Dialog';
import {showToast} from '../../Toast';
import {SelectItem} from '../../SelectItem';
import {useAllUsers} from '../../../hooks/useAllUsers';
import {useUpdateOfficer} from '../../../hooks/useLenderUpdateOfficer';

type Props = {
  setLoading: (loading: boolean) => void;
  loanApplicationId: string;
  open: boolean;
  onClose: () => void;
};

export const TransferLoanOfficerDialog = ({
  setLoading,
  onClose,
  open,
  loanApplicationId,
}: Props) => {
  const [dro, setDro] = useState<{id: string; name: string} | undefined>();

  const {users, loading, error} = useAllUsers({
    role: 'digital relationship officers',
  });

  const {
    loading: updatingOfficer,
    error: updateError,
    updateOfficer,
  } = useUpdateOfficer();

  useEffect(() => {
    if (error) {
      showToast({
        open: true,
        message: `An Error occurred when fetching users: ${error.message}`,
        positive: false,
      });
    }
    if (updateError) {
      showToast({
        open: true,
        message: `An Error occurred when updating officer: ${updateError.message}`,
        positive: false,
      });
    }
  }, [error, updateError]);

  useEffect(() => {
    if (loading || updatingOfficer) {
      setLoading(true);
    }
  }, [loading, updatingOfficer]);

  const handleSelect = (item: {id: string; name: string}) => {
    setDro(item);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    console.log('dro', dro);
    if (!dro) {
      showToast({
        open: true,
        message: `Please select a DRO`,
        positive: false,
      });
      setLoading(false);
      return;
    }
    try {
      console.log('officerId', dro.id, 'loanApplicationId', loanApplicationId);
      await updateOfficer({variables: {officerId: dro.id, loanApplicationId}});
      showToast({
        open: true,
        message: `${dro.name} has been assigned to this loan`,
        positive: true,
      });
      setDro(undefined);
    } catch (e: any) {
      showToast({
        open: true,
        message: `An Error occurred when saving comment: ${e.message}`,
        positive: false,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const getItems = () => {
    if (!users || !Array.isArray(users)) return [];
    return (
      users?.map(user => {
        return {id: user.id, name: user.firstName + ' ' + user.lastName};
      }) || []
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={'Select New DRO'}
      btnText="Transfer"
      description="Select a new DRO to own this loan"
      onSubmit={async e => await onSubmit(e)}>
      <>
        <SelectItem items={getItems()} onSelect={handleSelect} />
      </>
    </Dialog>
  );
};
