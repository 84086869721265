import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import {useLocation} from '@reach/router';
import {HomePageLayout} from '../HomePageLayout';
import {TitleBar} from '../TitleBar';
import {
  LoanApplicationType,
  RefetchType,
  useLoanApplication,
} from '../../hooks/useLoanApplication';
import {invalidInput, showToast, validInput} from '../Toast';
import {ExpandedPhotoDialog} from './components/ExpandedPhoto';
import {AddCommentDialog} from './components/AddCommentDialog';
import {AddContactAttemptDialog} from './components/AddContactAttemptDialog';
import {
  DEFAULT_CURRENCY,
  PHOTO_STATES,
  PHOTO_STATES_TYPE,
  VISIT_ATTEMPTS_OPTIONS,
} from '../../constants/constants';
import {useUpdateLoanApplicationStage} from '../../hooks/useUpdateLoanApplicationStage';
import {useResubmitPhoto} from '../../hooks/useResubmitPhoto';
import {
  LoanApplicationCategoryType,
  useUpdateLoanApplicationCategory,
} from '../../hooks/useUpdateLoanApplicationCategoryUpdate';
import {useUpdateLoanQuote} from '../../hooks/useUpdateLoanQuote';
import {AddVisitAttemptDialog} from './components/AddVisitAttemptDialog';
import {DisburseLoanDialog} from './components/DisburseLoanDialog';
import {useRequestLenderOtp} from '../../hooks/useRequestLenderOtp';
import {MutationFunctionOptions} from '@apollo/client';
import {routeToList} from './components/ActionButtons';
import {
  BorrowerObject,
  BorrowerPhotoType,
  BorrowerType,
  LoanApplicationStatusesType,
  Maybe,
  UpdatePhotoType,
} from '../../gql/graphql';
import {useUpdateBorrower} from '../../hooks/useUpdateBorrower';
import {useUpdateBusinessPhoto} from '../../hooks/useUpdateBusinessPhoto';
import {useUpdateBorrowerPhotos} from '../../hooks/useUpdateBorrowerPhoto';
import {useUploadFile} from '../../hooks/useUploadFile';
import {
  formatLoans,
  headers as loanHeaders,
  nameMatchesHeaders,
  paymentHeaders,
} from '../../routes/portfolio-manager';
import {UpdateBusinessTransactionsDialog} from '../UpdateBusinessTransactionsDialog';
import {useUpdateBusiness} from '../../hooks/useUpdateBusiness';
import {AddManualPaymentDialog} from '../AddManualPaymentDialog';
import {useProfileMatches} from '../../hooks/useProfileMatches';
import {useLoanPayments} from '../../hooks/useLoanPayments';
import {navigate} from 'gatsby';
import {formatMoney} from '../../utils/format-money';
import {useHasPermissions} from '../../hooks/useHasPermission';
import {permissions} from '../../constants/constants';
import {TransferLoanOfficerDialog} from './components/TransferLoanOfficerDialog';
import {AddPenaltyWaiverDialog} from '../AddPenaltyWaiver';

export type HideCards = {
  titleBar?: boolean;
  loanTerms?: boolean;
  mobileMoneyTransactions?: boolean;
  nextOfKin?: boolean;
  AccountDetails?: boolean;
  userPhotos?: boolean;
  purposeOfLoan?: boolean;
  adjustLoaAmount?: boolean;
  mobileMoneyNumber?: boolean;
  dueDiligenceLinks?: boolean;
  comments?: boolean;
  contactAttempts?: boolean;
  visitAttempts?: boolean;
  dismissalReasons?: boolean;
  actionButtons?: boolean;
};

type Props = {
  path?: string;
  applicationId: string;
  hideCards?: HideCards;
  stage?: LoanApplicationStatusesType;
};

export type PhotoStatusesType = {
  [x: string]:
    | {status: PHOTO_STATES_TYPE; photoType?: string; photoSource?: string}
    | undefined;
};

export type NewQuoteType = {
  amount?: string;
  error?: string;
  loanTerm?: string;
  termError?: string;
};

export type DetailPageProps = {
  setLoanDismissalReason: (reason: string | undefined) => void;
  getContactAttempts: () => {
    [x: string]: string | JSX.Element | Maybe<string> | undefined;
  }[];
  getVisitAttempts: () => {
    [x: string]: string | JSX.Element | Maybe<string> | undefined;
  }[];
  getComments: () => {
    [x: string]: string | JSX.Element | Maybe<string> | undefined;
  }[];
  declineLoan: (e: SyntheticEvent) => void;
  photoResubmit: (e: SyntheticEvent) => void;
  updateStage: (
    e: SyntheticEvent,
    stage: LoanApplicationStatusesType | undefined,
  ) => void;
  photoStatuses: PhotoStatusesType;
  loanApplication: LoanApplicationType;
  setLoanApplicationCategory: (category: LoanApplicationCategoryType) => void;
  setDialog: (object: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  }) => void;
  dialog: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  };
  loanApplicationCategory: LoanApplicationCategoryType;
  handleSubmitLoanApplicationCategory: (e: SyntheticEvent) => void;
  newQuote: NewQuoteType;
  setNewQuote: (newQuote: NewQuoteType) => void;
  handleSaveQuote: (e: SyntheticEvent) => void;
  requestOtp: (options: MutationFunctionOptions | undefined) => void;
  getLoans: () => {headers: any[]; items: any[]};
  hideCards: HideCards | undefined;
  stage?: LoanApplicationStatusesType;
  updateBorrower: (options: MutationFunctionOptions<BorrowerType>) => void;
  updateBusinessPhotos: (
    options: MutationFunctionOptions<UpdatePhotoType>,
  ) => void;
  updateBorrowerPhotos: (
    options: MutationFunctionOptions<BorrowerPhotoType>,
  ) => void;
  uploadFileUrl: (options: MutationFunctionOptions<any>) => any;
  borrower: BorrowerObject;
  setCustomLoading: (loading: boolean) => void;
  refetchLoanApplication: RefetchType;
  getProfileMatches: () => {headers: any[]; items: any[]};
  getLoanPayments: () => {headers: any[]; items: any[]};
};

const LoanApplicationDetailContext = React.createContext<DetailPageProps>(
  {} as any,
);

export const LoanApplicationDetail = ({
  applicationId,
  children,
  hideCards,
  stage,
}: React.PropsWithChildren<Props>) => {
  const {
    updateBusinessPhotos,
    loading: loadingBusinessPhotos,
    photos: businessPhotos,
  } = useUpdateBusinessPhoto();

  const {
    updateBorrowerPhotos,
    loading: loadingBorrowerPhotos,
    photos: borrowerPhotos,
  } = useUpdateBorrowerPhotos();

  const {uploadFileUrl, loading: uploadingFile} = useUploadFile();
  const {hasPermissions} = useHasPermissions();

  const {
    updateBusiness,
    loading: updatingBusiness,
    error: updateBusinessError,
  } = useUpdateBusiness();

  const {pathname} = useLocation();

  const {
    loanApplication,
    error,
    loading,
    refetch: refetchLoanApplication,
  } = useLoanApplication(applicationId);

  const {
    profileMatches,
    error: profileMatchesError,
    loading: profileMatchesLoading,
  } = useProfileMatches(applicationId);

  const {
    loanPayments,
    error: loanPaymentsError,
    loading: loanPaymentsLoading,
  } = useLoanPayments(applicationId);

  const {resubmitPhoto, loading: resubmittingPhoto} = useResubmitPhoto();
  const [loanDismissalReason, setLoanDismissalReason] = useState<
    string | undefined
  >();

  const {loading: updatingLoanQuote, updateLoanQuote} = useUpdateLoanQuote();
  const {requestOtp, loading: requestingOtp} = useRequestLenderOtp();
  const [loanApplicationCategory, setLoanApplicationCategory] =
    useState<LoanApplicationCategoryType>({});

  const [newQuote, setNewQuote] = useState<NewQuoteType>({
    amount: '',
    loanTerm: '',
  });

  const {
    loading: updatingLoanApplicationCategory,
    updateLoanApplicationCategory,
  } = useUpdateLoanApplicationCategory();

  const [photoStatuses, setPhotoStatuses] = useState<PhotoStatusesType>({});
  const {loading: updatingLoanApplicationStage, updateLoanApplicationStage} =
    useUpdateLoanApplicationStage();

  const {
    loading: updatingBorrower,
    borrower,
    updateBorrower,
  } = useUpdateBorrower();

  const [dialog, setDialog] = useState<{
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  }>({} as any);

  const getComments = () => {
    if (!loanApplication?.comments) return [];
    return loanApplication?.comments?.map(comment => ({
      comment: comment?.comment,
      date: new Date(comment?.createdAtUtc).toLocaleDateString(),
      staff: `${comment?.staff?.firstName} ${comment?.staff?.lastName}`,
    }));
  };

  const [customLoading, setCustomLoading] = useState<boolean>(false);

  const getContactAttempts = () => {
    if (!loanApplication?.contactAttempts) return [];
    return loanApplication?.contactAttempts?.map(attempt => ({
      channel: attempt?.channel,
      date: new Date(attempt?.createdAtUtc).toLocaleDateString(),
      staff: `${attempt?.staff?.firstName} ${attempt?.staff?.lastName}`,
      success: attempt?.contactedSuccessfully ? (
        <span className="text-green-600">Contacted Successfully</span>
      ) : (
        <span className="text-red-600">Contacted Unsuccessfully</span>
      ),
    }));
  };

  const getVisitAttempts = () => {
    if (!loanApplication?.visitAttempts) return [];
    return loanApplication?.visitAttempts?.map(attempt => ({
      status: (
        <span
          className={`${
            attempt?.status === VISIT_ATTEMPTS_OPTIONS.CONFIRMED
              ? 'text-green-600'
              : 'text-red-600'
          }`}>
          {attempt?.status}
        </span>
      ),
      date: new Date(attempt?.createdAtUtc).toLocaleDateString(),
      staff: `${attempt?.staff?.firstName} ${attempt?.staff?.lastName}`,
    }));
  };

  const getLoans = (): {headers: any[]; items: any[]} => {
    const items = !loanApplication?.business?.loans
      ? []
      : formatLoans(
          loanApplication?.business?.loans,
          pathname,
          loanApplication,
        );
    return {headers: loanHeaders, items};
  };

  const getProfileMatches = useCallback(() => {
    const matches = profileMatches?.map(application => ({
      id: application?.pk,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            const base = pathname.split('/').slice(0, -2).join('/');
            navigate(`${base}/${application.id}/detail`);
          }}>
          {application?.business?.name}
          <span className="text-red-400">
            {' '}
            ({loanApplication?.business?.name})
          </span>
        </div>
      ),
      clientName: application.business?.borrower?.nationalIdName,
      phone: application?.business?.borrower?.phoneNumber,
      mobileMoneyNumber: application?.business?.borrower?.mobileMoneyNumber,
    }));
    return {headers: nameMatchesHeaders, items: matches};
  }, [profileMatches]);

  const getLoanPayments = useCallback(() => {
    const matches = loanPayments?.map(payment => ({
      id: payment?.pk,
      datePaid: (
        <p className="font-medium">
          {new Date(payment?.createdAtUtc).toLocaleDateString()}
        </p>
      ),
      totalPaid: formatMoney(payment?.totalPaid, DEFAULT_CURRENCY),
      outStandingBalance: formatMoney(
        payment?.outStandingBalance,
        DEFAULT_CURRENCY,
      ),
      amount: (
        <p className="font-bold">
          {formatMoney(payment?.amountPaid, DEFAULT_CURRENCY)}
        </p>
      ),
      paymentType: payment?.paymentType === 'auto' ? 'In App' : 'Manual',
    }));
    return {headers: paymentHeaders, items: matches};
  }, [loanPayments]);

  const handleSubmitLoanApplicationCategory = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (
      !loanApplicationCategory.attitude ||
      loanApplicationCategory.attitude === 0
    ) {
      invalidInput('Update Borrower "Attitude" before you can save');
      return;
    }
    if (
      !loanApplicationCategory.honesty ||
      loanApplicationCategory.honesty === 0
    ) {
      invalidInput('Update Borrower "Honesty" before you can save');
      return;
    }
    if (
      !loanApplicationCategory.availability ||
      loanApplicationCategory.availability === 0
    ) {
      invalidInput('Update Borrower "Availability" before you can save');
      return;
    }
    if (
      !loanApplicationCategory.purposeOfLoanCategory ||
      loanApplicationCategory.purposeOfLoanCategory === ''
    ) {
      invalidInput(
        'Update Borrower "Purpose of Loan Category" before you can save',
      );
      return;
    }
    if (
      !loanApplicationCategory.purposeOfLoan ||
      loanApplicationCategory.purposeOfLoan === ''
    ) {
      invalidInput('Update Borrower "Purpose of Loan" before you can save');
      return;
    }
    try {
      await updateLoanApplicationCategory({
        variables: {
          loanApplicationId: loanApplication?.id,
          category: loanApplicationCategory,
        },
      });
      await refetchLoanApplication();
      validInput('Done updating loan application category');
    } catch (e: any) {
      console.error('Error when updating loan application category', e);
      invalidInput(`An error occurred when updating loan application category`);
    }
  };

  const handleSaveQuote = async (e: SyntheticEvent) => {
    if (
      (!newQuote?.amount || newQuote?.amount === '') &&
      (!newQuote?.loanTerm || newQuote?.loanTerm === '')
    ) {
      invalidInput(
        'Please enter proposed amount or term days before trying to save',
      );
      return;
    }
    try {
      await updateLoanQuote({
        variables: {
          quote: {proposedAmount: newQuote.amount, termDays: newQuote.loanTerm},
          loanApplicationId: loanApplication?.id,
        },
      });
      await refetchLoanApplication();
      validInput('Done updating proposed amount');
    } catch (e: any) {
      console.error('An error occurred when updating proposed amount', e);
      invalidInput(e.message);
    }
  };

  useEffect(() => {
    if (error) {
      console.error('error occurred when loading loan application', error);
      showToast({
        open: true,
        message: 'Error occurred when loading loan application',
        positive: false,
      });
    }
    if (updateBusinessError) {
      console.error(
        'error occurred when updating business',
        updateBusinessError,
      );
      showToast({
        open: true,
        message: 'Error occurred when updating business',
        positive: false,
      });
    }
    if (profileMatchesError) {
      console.error(
        'error occurred when fetching profile matches',
        profileMatchesError,
      );
      showToast({
        open: true,
        message: 'Error occurred when fetching profile matches',
        positive: false,
      });
    }
    if (loanPaymentsError) {
      console.error(
        'error occurred when fetching loan payments',
        loanPaymentsError,
      );
      showToast({
        open: true,
        message: 'Error occurred when fetching loan payments',
        positive: false,
      });
    }
  }, [error, updateBusinessError, profileMatchesError, loanPaymentsError]);

  useEffect(() => {
    const statuses = {} as any;
    if (loanApplication?.business?.borrower?.photos) {
      loanApplication?.business?.borrower?.photos?.map(photo => {
        if (photo?.id) {
          statuses[photo.id] = {};
          statuses[photo.id].status = photo?.status;
          statuses[photo.id].photoType = photo?.photoType;
          statuses[photo.id].photoSource = 'BORROWER';
        }
      });
    }
    if (loanApplication?.business?.photos) {
      loanApplication?.business?.photos?.map(photo => {
        if (photo?.id) {
          statuses[photo.id] = {};
          statuses[photo.id].status = photo?.status;
          statuses[photo.id].photoType = photo?.photoType;
          statuses[photo.id].photoSource = 'BUSINESS';
        }
      });
    }
    setPhotoStatuses(statuses);
  }, [
    loanApplication?.business?.borrower?.photos,
    loanApplication?.business?.photos,
  ]);

  useEffect(() => {
    if (
      Object.keys(loanApplicationCategory).length === 0 &&
      loanApplication?.category
    ) {
      setLoanApplicationCategory({
        attitude: loanApplication?.category?.attitude,
        availability: loanApplication?.category?.availability,
        honesty: loanApplication?.category?.honesty,
        purposeOfLoanCategory: loanApplication?.category?.purposeOfLoanCategory,
        purposeOfLoan: loanApplication?.category?.purposeOfLoan,
      });
    }
  }, [loanApplication?.category]);

  useEffect(() => {
    const _quote: NewQuoteType = {};
    if (newQuote.amount === '' && loanApplication?.loanQuote?.proposedAmount) {
      _quote.amount =
        '' +
        (loanApplication?.loanQuote?.proposedAmount ??
          loanApplication?.loanQuote?.principal);
    }
    if (newQuote.loanTerm === '' && loanApplication?.loanQuote?.termDays) {
      _quote.loanTerm = '' + loanApplication?.loanQuote?.termDays;
    }
    if (Object.keys(_quote).length > 0) {
      setNewQuote({...newQuote, ..._quote});
    }
  }, [
    loanApplication?.loanQuote?.proposedAmount,
    loanApplication?.loanQuote?.termDays,
  ]);

  const completedReview = () => {
    const notReviewed = Object.values(photoStatuses).filter(photo => {
      return photo?.status === PHOTO_STATES.UNKNOWN;
    });
    if (notReviewed.length > 0) {
      showToast({
        open: true,
        message: 'Review all photos before you can continue',
        positive: false,
      });
      return false;
    }
    return true;
  };

  const declineLoan = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!completedReview()) return;
    if (!loanDismissalReason || loanDismissalReason === '') {
      showToast({
        open: true,
        message:
          'Please select dismissal reason before submitting for dismissal',
        positive: false,
      });
      return;
    }
    try {
      await updateLoanApplicationStage({
        variables: {
          stage: {
            status: LoanApplicationStatusesType.Declined,
            comment: loanDismissalReason,
          },
          loanApplicationId: loanApplication?.id,
        },
      });
      showToast({
        open: true,
        message: 'Loan has been declined',
        positive: true,
      });
      routeToList(pathname);
    } catch (e: any) {
      console.error(
        'error occurred when updating loan application stage',
        e.message,
      );
      showToast({
        open: true,
        message: `Error when updating loan application status: ${e.message}`,
        positive: false,
      });
    }
  };

  const updateStage = async (
    e: SyntheticEvent,
    stage?: LoanApplicationStatusesType,
    preventRoute?: boolean,
  ) => {
    e.preventDefault();
    if (!preventRoute && !completedReview()) return;
    if (!stage) {
      showToast({
        message: 'No Loan Application Stage Provided',
        open: true,
        positive: false,
      });
      return;
    }
    try {
      await updateLoanApplicationStage({
        variables: {
          stage: {
            status: stage,
          },
          loanApplicationId: loanApplication?.id,
        },
      });
      showToast({
        open: true,
        message: `Loan stage has been updated to ${stage}`,
        positive: true,
      });
      if (!preventRoute) routeToList(pathname);
    } catch (e: any) {
      console.error(
        'error occurred when updating loan application stage',
        e.message,
      );
      showToast({
        open: true,
        message: `Error when updating loan application stage: ${e.message}`,
        positive: false,
      });
    }
  };

  const photoResubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!completedReview()) return;

    const resubmit = Object.values(photoStatuses).filter(photo => {
      return photo?.status === PHOTO_STATES.BAD;
    });

    if (resubmit.length === 0) {
      showToast({
        open: true,
        message: 'No photos to resubmit',
        positive: false,
      });
      return;
    }
    try {
      await resubmitPhoto({
        variables: {
          photos: resubmit.map(photo => ({
            photoType: photo?.photoType,
            photoSource: photo?.photoSource,
          })),
          loanApplicationId: loanApplication?.id,
        },
      });
      showToast({
        open: true,
        message: 'bad photos marked for resubmission',
        positive: true,
      });
      routeToList(pathname);
    } catch (e: any) {
      console.error('error occurred when resubmitting photo', e.message);
      showToast({
        open: true,
        message: `Error when resubmitting photo: ${e.message}`,
        positive: false,
      });
    }
  };

  const canAssignSelf = (): boolean => {
    switch (stage) {
      case LoanApplicationStatusesType.PreScreening:
        if (loanApplication && !loanApplication?.prescreener) return true;
        break;
      case LoanApplicationStatusesType.DueDiligence:
        if (loanApplication && !loanApplication?.dcoOfficer) return true;
        break;
      case LoanApplicationStatusesType.FinalReview:
        if (loanApplication && !loanApplication?.finalReviewer) return true;
        break;
    }
    return false;
  };

  const showAssignBtn = () => {
    return (
      stage &&
      [
        LoanApplicationStatusesType.PreScreening,
        LoanApplicationStatusesType.DueDiligence,
        LoanApplicationStatusesType.FinalReview,
      ].indexOf(stage) !== -1
    );
  };

  const showTransferBtn = () => {
    return hasPermissions([permissions.PERMISSION_UPDATE_LOAN_OFFICER.name]);
  };

  const showWaiverBtn = () => {
    return !!(
      hasPermissions([permissions.PERMISSION_WAIVE_PENALTY.name]) &&
      loanApplication?.loan?.penaltyAmount &&
      loanApplication?.loan?.penaltyAmount > 0
    );
  };

  useEffect(() => {
    if (businessPhotos) {
      validInput(`Business Photos updated successfully`);
    }
  }, [businessPhotos]);

  useEffect(() => {
    if (borrowerPhotos) {
      validInput('User Photos updated successfully');
    }
  }, [borrowerPhotos]);

  return (
    <LoanApplicationDetailContext.Provider
      value={{
        setLoanDismissalReason,
        getContactAttempts,
        getComments,
        declineLoan,
        photoResubmit,
        updateStage,
        photoStatuses,
        loanApplication,
        loanApplicationCategory,
        setLoanApplicationCategory,
        handleSubmitLoanApplicationCategory,
        dialog,
        setDialog,
        handleSaveQuote,
        newQuote,
        setNewQuote,
        getVisitAttempts,
        requestOtp,
        getLoans,
        hideCards,
        stage: loanApplication?.stage?.stage ?? stage,
        updateBorrower,
        borrower,
        updateBorrowerPhotos,
        updateBusinessPhotos,
        uploadFileUrl,
        setCustomLoading,
        refetchLoanApplication,
        getProfileMatches,
        getLoanPayments,
      }}>
      <HomePageLayout
        loading={
          loading ||
          dialog?.photo?.loading ||
          dialog?.comment?.loading ||
          updatingLoanApplicationStage ||
          resubmittingPhoto ||
          dialog?.contactAttempts?.loading ||
          updatingLoanApplicationCategory ||
          updatingLoanQuote ||
          dialog?.visitAttempts?.loading ||
          dialog?.disburse?.loading ||
          dialog?.updateTransactions?.loading ||
          dialog?.addManualPayment?.loading ||
          dialog?.transferLoan?.loading ||
          requestingOtp ||
          updatingBorrower ||
          loadingBorrowerPhotos ||
          loadingBusinessPhotos ||
          uploadingFile ||
          customLoading ||
          updatingBusiness ||
          profileMatchesLoading ||
          loanPaymentsLoading
        }>
        <TitleBar
          title="Application Details"
          description="/application/details"
          disabled={!canAssignSelf()}
          showAssignBtn={showAssignBtn()}
          showTransferBtn={showTransferBtn()}
          showPenaltyWaiverButton={showWaiverBtn()}
          update={async e => await updateStage(e, stage, true)}
        />
        <TransferLoanOfficerDialog
          loanApplicationId={loanApplication?.id}
          open={dialog.transferLoan?.open ?? false}
          onClose={() =>
            setDialog({
              ...dialog,
              transferLoan: {...dialog?.transferLoan, open: false},
            })
          }
          setLoading={loading =>
            setDialog({
              ...dialog,
              transferLoan: {...dialog?.transferLoan, loading},
            })
          }
        />
        <ExpandedPhotoDialog
          open={dialog.photo?.open ?? false}
          photo={dialog.photo?.data?.photo}
          photoGroup={dialog.photo?.data?.photoGroup}
          onClose={() =>
            setDialog({...dialog, photo: {...dialog?.photo, open: false}})
          }
        />
        <AddCommentDialog
          open={dialog?.comment?.open ?? false}
          onClose={() =>
            setDialog({...dialog, comment: {...dialog?.comment, open: false}})
          }
          setLoading={loading =>
            setDialog({...dialog, comment: {...dialog?.comment, loading}})
          }
          loanApplicationId={dialog?.comment?.data}
        />
        <AddContactAttemptDialog
          open={dialog?.contactAttempts?.open ?? false}
          onClose={() =>
            setDialog({
              ...dialog,
              contactAttempts: {...dialog?.contactAttempts, open: false},
            })
          }
          setLoading={loading =>
            setDialog({
              ...dialog,
              contactAttempts: {...dialog?.contactAttempts, loading},
            })
          }
          loanApplicationId={dialog?.contactAttempts?.data}
        />
        <AddVisitAttemptDialog
          open={dialog?.visitAttempts?.open ?? false}
          onClose={() =>
            setDialog({
              ...dialog,
              visitAttempts: {...dialog?.visitAttempts, open: false},
            })
          }
          setLoading={loading =>
            setDialog({
              ...dialog,
              visitAttempts: {...dialog.visitAttempts, loading},
            })
          }
          loanApplicationId={dialog?.visitAttempts?.data}
        />
        <DisburseLoanDialog
          open={dialog?.disburse?.open ?? false}
          onClose={() =>
            setDialog({...dialog, disburse: {...dialog?.disburse, open: false}})
          }
          setLoading={loading =>
            setDialog({...dialog, disburse: {...dialog?.disburse, loading}})
          }
          loanApplicationId={dialog?.disburse?.data}
        />
        <UpdateBusinessTransactionsDialog
          open={dialog?.updateTransactions?.open ?? false}
          onClose={() =>
            setDialog({
              ...dialog,
              updateTransactions: {...dialog?.updateTransactions, open: false},
            })
          }
          setLoading={loading =>
            setDialog({
              ...dialog,
              updateTransactions: {...dialog?.updateTransactions, loading},
            })
          }
          updateBusiness={updateBusiness}
          business={dialog?.updateTransactions?.data}
        />
        <AddManualPaymentDialog
          open={dialog?.addManualPayment?.open ?? false}
          onClose={() =>
            setDialog({
              ...dialog,
              addManualPayment: {...dialog?.addManualPayment, open: false},
            })
          }
          setLoading={loading =>
            setDialog({
              ...dialog,
              addManualPayment: {...dialog?.addManualPayment, loading},
            })
          }
        />

        <AddPenaltyWaiverDialog
          open={dialog?.waivePenalty?.open ?? false}
          onClose={() =>
            setDialog({
              ...dialog,
              waivePenalty: {...dialog?.waivePenalty, open: false},
            })
          }
          setLoading={loading =>
            setDialog({
              ...dialog,
              waivePenalty: {...dialog?.waivePenalty, loading},
            })
          }
        />
        <div className="flex flex-col w-full p-4">{children}</div>
      </HomePageLayout>
    </LoanApplicationDetailContext.Provider>
  );
};

export const useLoanApplicationDetail = () =>
  React.useContext<DetailPageProps>(LoanApplicationDetailContext);
