import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {DEFAULT_CURRENCY} from '../constants/constants';
import {Dialog} from './Dialog';
import {useLoanApplicationDetail} from './LoanApplicationDetail/LoanApplicationDetail';
import {SingleInput} from './SingleInput';
import {showToast} from './Toast';
import {useWaivePenalty} from '../hooks/useWaivePenalty';
import {Maybe} from '../gql/graphql';

type Props = {
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
};

type FormDataType = {
  amount?: Maybe<number> | undefined;
};

type FormDataErrorType = {
  [x: string]: any;
};

export const AddPenaltyWaiverDialog = ({open, onClose, setLoading}: Props) => {
  const [formData, setFormData] = useState<FormDataType>({});
  const [formDataErrors, setDataFormErrors] = useState<FormDataErrorType>({});

  const [submitting, setSubmitting] = useState<boolean>(false);

  const {waivePenalty, loading} = useWaivePenalty();

  const {loanApplication} = useLoanApplicationDetail();

  const {business} = loanApplication ?? {};

  const getInitialFormData = () => {
    return {
      amount: loanApplication?.loan?.penaltyAmount,
    };
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    setFormData({...formData, [name]: e.target.value});
    setDataFormErrors({...formDataErrors, [name]: undefined});
  };

  useEffect(() => {
    if (business) {
      setFormData({...getInitialFormData()});
    }
  }, [business]);

  useEffect(() => {
    if (!submitting && !loading) setLoading(false);
    if (submitting || loading) setLoading(true);
  }, [submitting, loading]);

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);
    let invalid = false;
    const errors: any = {};
    if (!formData.amount) {
      errors['amount'] = 'Please Enter waiver amount';
      invalid = true;
    }

    if (
      formData.amount &&
      loanApplication?.loan?.penaltyAmount &&
      formData.amount > loanApplication.loan.penaltyAmount
    ) {
      {
        errors[
          'amount'
        ] = `Waiver amount can not be greater the penalty amount of UGX: ${loanApplication.loan.penaltyAmount}`;
        invalid = true;
      }
    }
    if (invalid) {
      setDataFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      const waiverData = {
        amount: Number(formData.amount),
        loanId: loanApplication?.loan?.id,
      };
      console.log('waiverData', waiverData);
      await waivePenalty({
        variables: {...waiverData},
      });
      showToast({
        open: true,
        message: 'Penalty waived successfully',
        positive: true,
      });
    } catch (error: any) {
      console.error(error);
      showToast({
        open: true,
        message: `Penalty waiver failed: ${error.message}`,
        positive: false,
      });
    } finally {
      setSubmitting(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Add Penalty Waiver"
      btnText="Add Waiver Amount"
      onSubmit={async e => await onSubmit(e)}
      description="Add penalty waiver">
      <div>
        <SingleInput
          title="Amount"
          value={formData.amount ?? ''}
          type="number"
          prefix={DEFAULT_CURRENCY}
          // placeholder="How many clients do you want to acquire this month?"
          onChange={e => onChange(e, 'amount')}
          error={formDataErrors?.amount}
        />
      </div>
    </Dialog>
  );
};
