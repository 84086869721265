import dayjs from 'dayjs';
import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {DEFAULT_CURRENCY, ITEMS_PER_PAGE} from '../../constants/constants';
import {useAllLoans} from '../../hooks/useAllLoans';
import {LoanType} from '../../hooks/useLoanApplication';
import {formatMoney} from '../../utils/format-money';
import {useActiveTab} from '../../hooks/useActiveTab';
import {LoanApplicationObject, LoanStatesType, Maybe} from '../../gql/graphql';
import {baseRoute} from '../../components/LoanApplicationDetail/components/ActionButtons';
import Pagination from '../../components/Pagination';
import usePagination, {fetchingMore} from '../../hooks/usePagination';
import {useReactiveVar} from '@apollo/client';

type Props = {
  path?: string;
};

const CURRENT: 'Current' = 'Current';
const PAID_UP: 'Fully Paid' = 'Fully Paid';
const ARREARS: 'Arrears' = 'Arrears';
const DISBURSED: 'Disbursed' = 'Disbursed';
const FAILED: 'Failed' = 'Failed';

export const headers = [
  {name: 'ID'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Due Date'},
  {name: 'Principal Disbursed'},
  {name: 'Loan Amount'},
  {name: 'Penalty Amount'},
  {name: 'Total Amount Due'},
  {name: 'Status'},
  {name: 'Days Paid Late'},
  {name: 'Out Standing Amount'},
  {name: 'Phone Number'},
];

export const nameMatchesHeaders = [
  {name: 'Loan ID'},
  {name: 'Business Name'},
  {name: 'Profile Name'},
  {name: 'Phone Number'},
  {name: 'Mobile Phone number'},
];

export const paymentHeaders = [
  {name: 'Payment ID'},
  {name: 'Date Paid'},
  {name: 'Total Paid'},
  {name: 'Outstanding Balance'},
  {name: 'Amount Paid'},
  {name: 'Payment Type'},
];

export const formatLoans = (
  loans: LoanType[] | undefined | LoanType | Maybe<LoanType>[],
  pathname?: string,
  loanApplication?: LoanApplicationObject | undefined,
) => {
  if (!loans || !Array.isArray(loans)) return [];
  return loans.map(loan => {
    return {
      id: loan?.pk,
      businessName: (
        <a
          className="px-2 text-start text-blue-500 cursor-pointer"
          // target="_blank"
          href={`${baseRoute(pathname)}${loan?.loanApplication?.id}/detail`}>
          {loan?.business?.name ?? loanApplication?.business?.name}
        </a>
      ),
      clientName:
        loan?.business?.borrower?.name ??
        loanApplication?.business?.borrower?.name,
      dueDate: dayjs(loan?.loanEndTimeUtc).format('DD/MM/YYYY'),
      principal: formatMoney(loan?.principal, DEFAULT_CURRENCY),
      loanAmount: formatMoney(loan?.amount, DEFAULT_CURRENCY),
      penaltyAmount: formatMoney(loan?.penaltyAmount, DEFAULT_CURRENCY),
      amountDue: formatMoney(loan?.amountDue, DEFAULT_CURRENCY),
      status: loan?.latestStage,
      //
      daysPaidLate:
        loan?.daysPaidLate === null || loan?.daysPaidLate === undefined ? (
          <div
            className={`${
              loan?.daysInArrears == 0 ? 'text-green-600' : 'text-red-600'
            }`}>
            {loan?.daysInArrears ?? 'Unknown'}
          </div>
        ) : loan?.daysPaidLate > 0 ? (
          <div className="text-red-600">{loan?.daysPaidLate}</div>
        ) : (
          <div className="text-green-600">Paid on time</div>
        ),
      outStandingAmount: formatMoney(
        loan?.outStandingBalance,
        DEFAULT_CURRENCY,
      ),
      phone:
        loan?.business?.borrower?.phoneNumber ??
        loanApplication?.business?.borrower?.phoneNumber,
    };
  });
};

export const Portfolio = ({}: Props) => {
  const [activeTab, setActiveTab] = useState<string>(CURRENT);

  const loanStatusType = useMemo(() => {
    switch (activeTab) {
      case CURRENT:
        return LoanStatesType.Current;
      case PAID_UP:
        return LoanStatesType.PaidInFull;
      case DISBURSED:
        return LoanStatesType.Disbursed;
      case ARREARS:
        return LoanStatesType.InArrears;
      case FAILED:
        return LoanStatesType.DisbursementFailed;
      default:
        return LoanStatesType.Current;
    }
  }, [activeTab]);

  const {loans, error, loading, fetchMore, totalCount, pageInfo} = useAllLoans([
    loanStatusType,
  ]);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  const [prevLoans, setPrevLoans] = useState<LoanType[] | undefined>([]);

  useActiveTab({setTab: setActiveTab, defaultTab: CURRENT, tab: activeTab});

  const loadingMore = useReactiveVar(fetchingMore);

  const _formatLoans = (loans: LoanType[] | undefined) =>
    formatLoans(loans, '/app/portfolio/');

  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (error) {
      console.error('loans', error);
      invalidInput(
        `Error occurred when loading loan active ${loanStatusType.toLowerCase()} loans`,
      );
    }
  }, [error]);

  useEffect(() => {
    if (searchText.length > 0) {
      const newLoans = loans?.filter(
        (loan: LoanType) =>
          new RegExp(searchText, 'i').test(loan?.business?.name ?? '') ||
          new RegExp(searchText, 'i').test(
            loan?.business?.borrower?.phoneNumber ?? '',
          ) ||
          new RegExp(searchText, 'i').test(
            loan.business?.borrower?.name ?? '',
          ) ||
          new RegExp(searchText, 'i').test(
            new Date(loan?.createdAtUtc).toLocaleDateString() ?? '',
          ),
      );
      setItems(_formatLoans(newLoans));
    }
  }, [searchText, activeTab]);

  useEffect(() => {
    if (!loans || !Array.isArray(loans)) return;
    if (!_.isEqual(prevLoans, loans)) {
      setItems(_formatLoans(loans));
      setPrevLoans(loans);
    }
  }, [loans]);

  const {currentPage, totalPages, goToNextPage, goToPreviousPage} =
    usePagination(ITEMS_PER_PAGE, totalCount, activeTab);
  return (
    <HomePageLayout loading={loading || loadingMore}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Portfolio Manager" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search
            searchText={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <div className="w-8/12">
            <Tabs
              tabs={[
                {title: DISBURSED, active: activeTab === DISBURSED},
                {title: CURRENT, active: activeTab === CURRENT},
                {title: PAID_UP, active: activeTab === PAID_UP},
                {title: ARREARS, active: activeTab === ARREARS},
                {title: FAILED, active: activeTab === FAILED},
              ]}
              onClick={title => {
                setActiveTab(title);
              }}
            />
          </div>
          <ItemsTable headers={headers} items={items} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalCount}
            goToNextPage={(fetchMore, endCursor) =>
              goToNextPage(fetchMore, endCursor, 'loans')
            }
            goToPreviousPage={(fetchMore, startCursor) =>
              goToPreviousPage(fetchMore, startCursor, 'loans')
            }
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};
