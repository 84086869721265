import {ApolloError, useMutation, gql} from '@apollo/client';

const WAIVE_PENALTY_MUTATION = gql`
  mutation WaivePenaltyMutation($loanId: ID!, $amount: Float!) {
    waivePenalty(loanId: $loanId, amount: $amount) {
      waiver {
        amount
        id
      }
    }
  }
`;
export type UseWaivePenaltyType = {
  waivePenalty: any;
  loading: boolean;
  error: ApolloError | null | undefined;
};

export const useWaivePenalty = (): UseWaivePenaltyType => {
  const [waivePenalty, {loading, error}] = useMutation(WAIVE_PENALTY_MUTATION);
  return {
    waivePenalty,
    loading,
    error,
  };
};
