import React, {SyntheticEvent, useEffect, useState} from 'react';
import {PanZoom} from 'react-easy-panzoom';
import _ from 'lodash';
import {Dialog} from '../../../components/Dialog';
import {Maybe} from '../../../gql/graphql';
import {PhotosType} from '../../../hooks/useLoanApplication';
import {invalidInput, validInput} from '../../Toast';
import {useLoanApplicationDetail} from '../LoanApplicationDetail';

type Props = {
  photo?: PhotosType;
  open: boolean;
  photoGroup?: string;
  onClose: () => void;
};

export const ExpandedPhotoDialog = ({
  photo,
  onClose,
  open,
  photoGroup,
}: Props) => {
  const [rotation, setRotation] = useState<number>(0);
  const [image, setImage] = useState<Maybe<string> | undefined>(photo?.url);

  const [file, setFile] = useState<File | null>(null);

  const {
    loanApplication,
    updateBorrowerPhotos,
    updateBusinessPhotos,
    uploadFileUrl,
    setCustomLoading,
  } = useLoanApplicationDetail();

  useEffect(() => {
    if (!image && photo?.url) {
      setImage(photo?.url);
    } else if (image && photo?.url && photo?.url !== image) {
      setImage(photo?.url);
    }
  }, [photo?.url]);

  const fileUploadHandler = async () => {
    if (!file) {
      return;
    }
    const [, ext] = file.name.split('.');
    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    if (!allowedExtensions.includes(ext)) {
      invalidInput(
        `Invalid file extension: ${ext}. Allowed extensions: ${allowedExtensions.join(
          ', ',
        )}`,
      );
      return;
    }

    const {data} = await uploadFileUrl({
      variables: {
        phone: loanApplication?.business?.borrower?.phoneNumber,
        ext,
        type: photo?.photoType,
      },
    });
    if (!data?.fileUploadUrl?.url) {
      invalidInput('Failed to upload file');
      return;
    }
    await fetch(data?.fileUploadUrl?.url, {
      method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data'},
      body: file,
    });
    return data?.fileUploadUrl?.url?.split('?')[0];
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }
    setImage(URL.createObjectURL(file));
    setFile(file);
  };

  const handlePickFile = (e: SyntheticEvent) => {
    e.preventDefault();
    const input = document.getElementById(
      'dialog-file-input',
    ) as HTMLInputElement;
    input.accept = 'image/*';
    input.click();
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    setCustomLoading(true);
    const url = await fileUploadHandler();
    if (!url) {
      // invalidInput('file upload failed');
      setCustomLoading(false);
      return;
    }
    const _photo = {
      id: photo?.id,
      photo: {url, status: 'GOOD'},
    };
    try {
      switch (photoGroup) {
        case 'BORROWER':
          await updateBorrowerPhotos({variables: {photos: [_photo]}});
          break;
        case 'BUSINESS':
          await updateBusinessPhotos({variables: {photos: [_photo]}});
          break;
        default:
          throw 'Unknown photo group';
      }
      validInput('Photos updated.');
    } catch (e: any) {
      console.error('error occurred when updating photo', e);
      invalidInput(`Error occurred when updating photo: ${e.message}`);
    }
    setCustomLoading(false);
  };

  const rotateRight = () => {
    if (rotation === 360) {
      setRotation(90);
    } else {
      setRotation(rotation + 90);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={photo?.photoType ?? 'Photo'}
      btnText="Ok"
      description={photo?.status ?? 'Photo Status'}
      onSubmit={() => onClose()}>
      <div className="flex flex-row">
        <button
          className="rounded-3xl px-8 py-2 border border-gray-500 grow shadow"
          onClick={handlePickFile}>
          UPLOAD NEW PHOTO
        </button>
        {file && (
          <button
            className="rounded-3xl px-8 py-2 text-white border border-green-500 bg-green-500 mx-2 shadow"
            onClick={async e => await handleSubmit(e)}>
            Save
          </button>
        )}
      </div>

      <input
        id="dialog-file-input"
        className="hidden"
        type="file"
        onChange={handleChange}
      />
      <div
        onClick={rotateRight}
        className={`flex h-3/5 max-w-screen-lg w-auto flex items-center 
          justify-center bg-red-200 m-2 rounded-lg cursor-pointer`}>
        <PanZoom>
          <img
            src={(image as string) ?? ''}
            className="w-auto max-w-screen-lg h-3/5 "
            style={{
              height: '520px',
              transform: `rotate(${rotation}deg)`,
            }}
          />
        </PanZoom>
      </div>
    </Dialog>
  );
};
