import React from 'react';
import {useState} from 'react';

type ListItem = {
  id: string;
  name: string;
};

type Props = {
  items: ListItem[];
  onSelect: (item: ListItem) => void;
};

export const SelectItem: React.FC<Props> = ({items, onSelect}) => {
  const [selectedItem, setSelectedItem] = useState<ListItem | null>(null);

  const handleSelect = (item: ListItem) => {
    setSelectedItem(item);
    onSelect(item);
  };

  return (
    <div>
      {selectedItem?.name && (
        <p className="my-2 text-lg">
          New DRO: <span className="font-bold">{selectedItem?.name}</span>
        </p>
      )}
      <ul>
        {items.map(item => (
          <li
            key={item.id}
            onClick={() => handleSelect(item)}
            style={{
              cursor: 'pointer',
              fontWeight: selectedItem?.id === item.id ? 'bold' : 'normal',
            }}>
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
